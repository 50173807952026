/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import ModalContainer from "../../../containers/ModalContainer";
import CardModalAuth from "../../../containers/CardModalAuth";
import {ContentCard, BigText, AppText, AuthButton} from "../../../styles/AuthStyles";
import {useLocation, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import Input from "../../../components/auth/Input";
import {useParams} from "react-router-dom";
import axios from 'axios';
import {apiUrl} from "../../../services/ApiUrl";

export default function ReferalModal() {
   const {fetching} = useSelector((state) => state.auth);
   const location = useLocation();
   const history = useHistory();

   const { id_event } = useParams();
   const [email, setEmail] = useState("");
   const [error, setError] = useState("");

   const userProfileData = useSelector((state) => state.auth);

   const handleInputChange = (event) => {
      setEmail(event.target.value);
   };


   const sendReferral = () => {
      console.log("Enviando correo a:" + email, "del evento", id_event);
      setError("");
      axios.post(apiUrl+'ticket/create_referal_ticket',{event_id:id_event,email:email},
         {
            headers: {
               Authorization: `Bearer ${userProfileData.token}`,
            },
         }
      ).then((res) => {

         if(res.data.status === "success"){
            alert("El ticket ha sido enviado al correo del referido.");
            history.replace(location.pathname);
         }

         if(res.data.status === "error"){

            switch(res.data.message){
               case "No membership":
                  setError("Debes tener una membresía para poder referir a alguien.");
                  break;
               case "User already referred someone":
                  setError("Solamente puedes referir a una persona.");
                  break;
               case "User already has a ticket for this event":
                  setError("El usuario ya tiene un boleto para este evento.");
                  break;
               case "Error creating ticket":
                  setError("Error creando el boleto, intenta de nuevo más tarde.");
                  break;
               default:
                  setError("Algo salió mal, intenta de nuevo más tarde.");
                  break;
            }

         }

      }).catch((res) => {
         console.log("error");
         console.log(res);
         setError("Algo salió mal, intenta de nuevo más tarde.");
      });
   }
   

   return (
      <ModalContainer
         close={() => {
            history.replace(location.pathname);
         }}
      >
         <CardModalAuth
            close={() => {
               history.replace(location.pathname);
            }}
            back={() => {
               history.replace(location.pathname);
            }}
            isLoading={fetching}
         >
            <ContentCard>
                <BigText>Escribe el correo de tu invitado</BigText>
                <br/>
                <div>
                    <Input
                        type="text" 
                        name="email" 
                        placeholder="Correo del referido" 
                        value={email} 
                        onChange={handleInputChange} 
                        required 
                     />

                        {error && (
                           <AppText color="crimson" style={{marginTop: "0.5em"}}>
                              {error}
                           </AppText>
                        )}

                     <AuthButton onClick={sendReferral}>Enviar</AuthButton>
               </div>
            </ContentCard>
         </CardModalAuth>
      </ModalContainer>
   );
}
