/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef, useState, useEffect} from "react";
import AnimatedView from "../../../containers/AnimatedView";
import Cover from "../../../components/cover/Cover";
import {useLocation, useHistory} from "react-router-dom";
import axios from 'axios';
import {apiUrl} from "../../../services/ApiUrl";
import {photosUrl} from "../../../services/PhotosUrl";
import usePrevious from "../../../hooks/usePrevious";
import {Container, Grid, Paper, Box, Typography } from '@mui/material';
import {Button} from "../../../styles/ButtonStyles";
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import {useSelector} from "react-redux";
import {GetTicketEvent} from "../../../services/MetaEvents";
import {useParams} from "react-router-dom";

export default function EventInfo() {
   const history = useHistory();
   const eventsRef = useRef(null);
   const viewRef = useRef(null);
   const location = useLocation();
   let prevLocation = usePrevious(location);

   const { id_event } = useParams();
   //const event_id = location.state.event_id;
   const event_id = id_event;

   const userProfileData = useSelector((state) => state.auth);

   const [eventInfo, setEventInfo] = useState([]);
   const [error, setError] = useState(null);
   const [comedians, setComedians] = useState([]);


   useEffect(() => {
      const fetchData = async () => {
         try {
            const responseEventInfo = await axios.get(apiUrl + 'event/event_info/' + event_id);
            let data = responseEventInfo.data.data;
            let hour = data.time.split(':');
            data.time = `${hour[0]}:${hour[1]}`;
            data.date = format(new Date(data.date), 'dd/MM/yyyy', { locale: es });
            setEventInfo(data);
      
            const responseComedians = await axios.get(apiUrl + 'comedians/event_comedians/' + event_id);
            let comediansData = responseComedians.data.data;
            setComedians(comediansData);
         } catch (err) {
            console.error("Error en fetchData", err);
            setError(err);
         }
      };
   
      fetchData();
   
   }, [location]);

   const makeReservation = () => {

      if(!userProfileData.token){
         window.location.href = "/?modal=login";
         return;
      }

      axios.post(apiUrl+'ticket/create_ticket',{event_id:event_id},
         {
            headers: {
               Authorization: `Bearer ${userProfileData.token}`,
            },
         }
      )
      .then(response => {
         console.log(response.data);
         if(response.data.status === 'success'){
            alert("Ticket reservado con éxito");
            history.push({
               pathname: "/ticket_list",
            });

            GetTicketEvent()
            .then(response => response.json()) // Si deseas ver la respuesta
            .then(data => {
               console.log("/////////RESPONSE META DATA//////////");
               console.log(data);
            })
            .catch(err => {
               console.error("Error en el request a Meta:", err);
            });

         }
         if(response.data.status === 'error'){

            if(response.data.message == 'El usuario no tiene una membresía activa'){
               window.location.href = "/?modal=user_membership";
            }
            else{
               alert("Algo salió mal, por favor intenta más tarde");
            }
         }
      })
      .catch(err => {
        setError(err);
        alert("Algo salió mal, por favor intenta más tarde");
      });
   };

   const referAction = () => {
      if(!userProfileData.token){
         window.location.href = "/?modal=login";
         return;
      }

      window.location.href = "/event_info/"+id_event+"?modal=referal_modal";

   };

   return (
      <>
         <AnimatedView showNav={false} ref={viewRef}>
            <Cover background={null}>
                <Container style={{marginTop:'120px'}}>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <Paper style={{ padding: 20, minHeight:'300px', color:'black', cursor:'pointer' }}>
                                <Box 
                                    component="img" 
                                    alt="" 
                                    src={`${photosUrl}${eventInfo.inner_img}`} 
                                    sx={{ width: '100%', height: 'auto' }} 
                                />
                            </Paper>
                        </Grid>

                        <Grid item md={6} style={{ padding: 20}}>
                            <Typography variant="h4" gutterBottom style={{ padding: 20, textAlign:"center"}}>
                                {eventInfo.title}
                            </Typography>
                            <Typography variant="p" gutterBottom>
                                {eventInfo.description}
                            </Typography>
                            <br/><br/>
                            <Typography variant="p" gutterBottom>
                                <strong>Dirección:</strong> {eventInfo.location}
                            </Typography>                            
                            <br/><br/>
                            <Typography variant="p" gutterBottom>
                                <strong>Fecha:</strong> {eventInfo.date}
                            </Typography>
                            <br/><br/>
                            <Typography variant="p" gutterBottom>
                                <strong>Hora:</strong> {eventInfo.time}
                            </Typography>
                            <Grid container spacing={2} style={{paddingTop:"20px"}}>
                              <Grid item md={6} style={{textAlign:"center"}}>
                                 <Button onClick={makeReservation} className="onlineButton">
                                    Obtener ticket
                                 </Button>
                              </Grid>  
                              <Grid item xs={6} style={{textAlign:"center"}}>
                                 
                                 <Button className="onlineButton" onClick={referAction}>
                                    REFERIR
                                 </Button>
                                    
                              </Grid>
                            </Grid>
                        </Grid>
                        {
                           (comedians.length > 0)&&
                           <Grid item md={12} style={{marginTop: "40px"}}>
                              <Typography variant="h4" gutterBottom style={{ padding: 20, textAlign:"center"}}>
                                 Comediantes invitados
                              </Typography>
                           </Grid>
                        }
                        {
                           (comedians.length > 0)&&
                              comedians.map((comedian, index) => (
                                 <Grid item md={4} key={index} style={{ padding: 20, marginTop: "5px"}}>
                                    <Typography variant="h6" gutterBottom style={{ padding: 5, textAlign:"center"}}>
                                       {comedian.comedian.name}
                                    </Typography>
                                    <Paper style={{ padding: 20, minHeight:'300px', color:'black', cursor:'pointer' }}>
                                       <Box 
                                             component="img" 
                                             alt="" 
                                             src={`${photosUrl}${comedian.comedian.photo}`} 
                                             sx={{ width: '100%', height: 'auto' }} 
                                       />
                                    </Paper>
                                 </Grid>
                              ))
                        }

                    </Grid>
                    


                </Container>
            </Cover>
         </AnimatedView>
      </>
   );
}
