//eslint-disable react-hooks/exhaustive-deps
import React, {useEffect, useState} from "react";
import LoginView from "../public/views/login/Login";
import {useTransition, animated} from "react-spring";
import RegisterView from "../public/views/register/RegisterView";
import LobbyView from "../private/views/lobby/LobbyView";
import InsertCodeView from "../private/views/insert_code/InsertCodeView";
import PaysMethodView from "../private/views/pays_method/PaysMethodView";
import TransferPayView from "../private/views/transfer/TransferPayView";
import PayByCode from "../private/views/pays_method/PayByCode";
import PayWhitCardView from "../private/views/cards/PayUserSub";
import PaypalView from "../private/views/paypal/PaypalView";
import EditProfileView from "../private/views/edit_profile/EditProfileView";
import ForgotPasswordView from "../public/views/forgot_password/ForgotPasswordView";
import TermsAndConditions from "../public/views/terms/TermsAndConditions";
import PrivacyPolice from "../public/views/privacy/PrivacyPolice";
import useQuery from "../hooks/useQuery";
import PayUserSub from "../private/views/cards/PayUserSub";
import PayBusinessSub from "../private/views/cards/PayBusinessSub";
import UserMembership from "../public/views/EventInfo/UserMembership";
import ReferalModal from "../public/views/EventInfo/ReferalModal";

// desde aquí se van a controlar los modales que se activen con ?modal=key
const ModalsRouter = () => {
   const [currentModal, setCurrent] = useState(null);
   let query = useQuery().get("modal");

   const transitions = useTransition(currentModal, null, {
      from: {opacity: 0},
      enter: {opacity: 1},
      leave: {opacity: 0},
   });

   useEffect(() => {
      setCurrent(query);
   }, [query]);

   useEffect(() => {
      // cambio de modal
      if (currentModal) {
         // Evitar scroll en body
         document.body.style.overflow = "hidden";
      } else {
         // Reactivar scroll en body
         document.body.style.overflow = "initial";
      }
   }, [currentModal]);

   // if (userType === "") return null;

   return transitions.map(({item, props, key}) => {
      return (
         <animated.div key={key} style={props}>
            <SwitcChildrens current={currentModal} />
         </animated.div>
      );
   });
   // return <SwitcChildrens current={currentModal} />;
};

const SwitcChildrens = ({current}) => {
   return (
      <>
         {React.Children.toArray(
            ModalsList.map((itemModal, index) => {
               if (current === itemModal.trigger) return <itemModal.component isActive={true} key={index} />;
               return null;
            }),
         )}
      </>
   );
};

export default ModalsRouter;

const ModalsList = [
   {
      trigger: "login",
      component: LoginView,
   },
   {
      trigger: "forgot",
      component: ForgotPasswordView,
   },
   {
      trigger: "register",
      component: RegisterView,
   },
   {
      trigger: "edit-profile",
      component: EditProfileView,
   },
   {
      trigger: "lobby",
      component: LobbyView,
   },
   {
      trigger: "insert-code",
      component: InsertCodeView,
   },
   {
      trigger: "pays",
      component: PaysMethodView,
   },
   {
      trigger: "cards",
      component: PayUserSub,
   },
   {
      trigger: "cards_business",
      component: PayBusinessSub,
   },   
   {
      trigger: "paypal",
      component: PaypalView,
   },
   {
      trigger: "transfer",
      component: TransferPayView,
   },
   {
      trigger: "membership-code",
      component: PayByCode,
   },
   {
      trigger: "terms",
      component: TermsAndConditions,
   },
   {
      trigger: "user_membership",
      component: UserMembership,
   },
   {
      trigger: "referal_modal",
      component: ReferalModal,
   },
];
